#Home{
    min-height: 100vh;

}
.home-text{
    position:relative;
    padding:1.5rem;
    margin:auto;
    top:5rem;
    border-radius: 10px;
    width:90%;
    max-width: 420px;
}
.home-text > h1 {
    color:#69d9b0;
    line-height: 1.25em;
    letter-spacing: 0.1em;
}
.home-text p {
    margin:1rem 0 0;
    line-height: 1.5em;
    font-family: "Roboto" !important;
    font-weight: 400;
    color: #fff;
}

@media (min-width: 1000px) {
    .home-text{
        margin-left: 60%; 
        top:10rem;
    }

    

}
