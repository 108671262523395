.main-header {
  width: 100%;
  height: 5rem;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background: #366680;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.26);
  padding: 0 1.5rem;
  z-index: 5;
}



@media (min-width: 768px) {
  .main-header {

  }
}