  
#Portfolio{
    height:100vh;
    width:100%;
    background:#69d9b0;
    color:#fff;
    position: relative;
    align-items: center;
    display: flex;

}
#Portfolio > div {
    position: relative;
    margin:auto;
    width:90%;
    max-width:800px;
   height:50vh;
   color:#366680;
 
}


.work-grid{
    display: grid; 
    grid-template-columns: 1fr 1fr; 
    grid-template-rows: 1fr 1fr; 
    gap: 1em 1em; 
    grid-template-areas: 
      ". ."
      ". ."; 
    justify-items: center;
    margin: 1rem 0;
}

.work-grid > div {
    text-align: center;
    background-color:#366680;
    width:100%;
    padding:4rem 0;
}

.work-grid > div > h5{
    color:#fff;
    font-size: 1em;
}