@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap');

*{
    margin:0;
    padding:0;
    font-family: "CodeBold";
}
h1,h2{
  color:#69d9b0;
}
h5{
  color:#69d9b0;
  font-size: 1.5em;
}
h5.reverse{
  color:#fff;
}
h1.reverse{
  color:#366680;
}
p{
  font-family: "Roboto";
  color:#fff;
}
p.reverse{
  color:#366680;
}
@font-face {
    font-family: "CodeLight";
    src: url("./fonts/CodeLight.eot"); /* IE9 Compat Modes */
    src: url("./fonts/CodeLight.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
      url("./fonts/CodeLight.otf") format("opentype"), /* Open Type Font */
      url("./fonts/CodeLight.svg") format("svg"), /* Legacy iOS */
      url("./fonts/CodeLight.ttf") format("truetype"), /* Safari, Android, iOS */
      url("./fonts/CodeLight.woff") format("woff"), /* Modern Browsers */
      url("./fonts/CodeLight.woff2") format("woff2"); /* Modern Browsers */
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "CodeBold";
    src: url("./fonts/CodeBold.eot"); /* IE9 Compat Modes */
    src: url("./fonts/CodeBold.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
      url("./fonts/CodeBold.otf") format("opentype"), /* Open Type Font */
      url("./fonts/CodeBold.svg") format("svg"), /* Legacy iOS */
      url("./fonts/CodeBold.ttf") format("truetype"), /* Safari, Android, iOS */
      url("./fonts/CodeBold.woff") format("woff"), /* Modern Browsers */
      url("./fonts/CodeBold.woff2") format("woff2"); /* Modern Browsers */
    font-weight: normal;
    font-style: normal;
  }
