#About{
    height:100vh;
    display: flex;
    width:100%;
    color: #366680;
}

#About > div {
    margin: auto;
}
#About > div > div {
    position: relative;
    display: flex;
    
}

.partner-imgs{
    width: 90%;
    margin:0.5rem auto;
}
.partner-imgs > div{
    flex:1;
    margin:auto 0;
    padding:0 0.25rem;
 
}
.partner-img{
   
}

#About > div > div:first-child{
    width: 95%;
    max-width: 1000px;
    margin: auto;
    display: flex;
    flex-direction: column;
}

#About > div > div:first-child > div:first-child{
    flex:3;
    margin: auto;
    padding:0.5rem;
}
#About > div > div:first-child > div:last-child{
    flex:2;
    margin: auto;
    padding:0.5rem;


}

#About > div > div > div > h2{
    color: #69d9b0;
    font-size: 2em;
    margin:0.5em 0;
}
#About > div > div > div > div > h5{
    color: #69d9b0;
    font-size: 1.5em;
}
#About > div > div > div > div > p{

    font-size: 0.75em;
}
#About > div > div > div > p {
    font-family: "Roboto";
    font-size: 1em;
  
}
.about-grid{
    display: grid; 
    grid-template-columns: 1fr 1fr; 
    grid-template-rows: 1fr 1fr; 
    gap: 0.25em 0.25em; 
    grid-template-areas: 
      ". ."
      ". ."; 
    justify-items: center;
    text-align: center;
}
.about-grid > div{
    padding: 1rem 0;
    font-size: 1.5em;
}
.about-grid > div > h5{
    margin:0.25em 0;
}
@media (min-width: 1000px) {
    #About > div > div:first-child{
        flex-direction: row;
    }
    .partner-imgs > div{
        padding: 0 0.85rem;
    }
    #About > div > div {
       
    }
}