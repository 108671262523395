#Vehicles{
    height:100vh;
    background-color: #366680;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    padding: 4rem 0;
}
#Vehicles > div {
    width:90%;
    max-width: 500px;
    margin: auto;
    line-height: 1.25;
    color: #fff;
    
}
#Vehicles > div:first-child{
}

#vehicles-box{
}
#vehicles-box img{
    display: block;
    height:12%;
    max-height: 75px;
    padding: 0.5rem 0;
    margin-left: auto;
}



@media (min-width: 1000px) {

    #Vehicles{
        flex-direction: row;

    }
    #Vehicles > div:first-child{
        margin:auto;
    
    }
    #vehicles-box{
        position: absolute;
        bottom:25%;
        right:-2%;
    }
    #vehicles-box > img{
    
        display: block;
        padding:1rem;
        margin-left: auto;
    }
}