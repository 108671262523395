#contact{
    display: flex;
    min-height: 100vh;
    margin-top:3rem;
    flex-direction:column;

}
#contact > div{
    background-color: #fff;
    padding:1rem;
    color:#366680;
    width:90%;
    max-width: 500px;
    margin:auto;
 

}
#contact img{
    padding:1rem 0;
}
#contact > div > p {
    font-family: 'roboto';
}
#contact p>a {
    color:#36668081;
    text-decoration: none;
    font-family: 'roboto';
}
#contact p>a:hover{
    color:#366680;
    text-decoration:underline;
    transition:0.8s;
}
.contact-right{
    display:flex;
    ;
} 
.contact-right>div{
    flex:4;
    margin:auto 0;
   
}

.contact-right > div:first-child{
    flex:0.5;
}


@media (min-width: 1000px) {
    #contact{
        margin-top:0;
        flex-direction:row;
    
    }

}