@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-header {
  width: 100%;
  height: 5rem;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background: #366680;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.26);
  padding: 0 1.5rem;
  z-index: 5;
}



@media (min-width: 768px) {
  .main-header {

  }
}
.nav-links {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nav-links li {
  margin: 1rem;
}

.nav-links a {
  border: 1px solid transparent;
  color: #fff;
  text-decoration: none;
  padding: 0.5rem;
}

.nav-links a:hover,
.nav-links a:active,
.nav-links a.active {
  background: #366680;
  border-color: #fff;
  color: #fff;
}

.nav-links button {
  cursor: pointer;
  border: 1px solid #292929;
  color: #292929;
  background: transparent;
  padding: 0.5rem;
  font: inherit;
}

.nav-links button:focus {
  outline: none;
}

.nav-links button:hover,
.nav-links button:active {
  background: #292929;
  color: white;
}

@media (min-width: 768px) {
  .nav-links {
    flex-direction: row;
  }

  .nav-links li {
    margin: 0 0.5rem;
  }

  .nav-links a {
    color: white;
    text-decoration: none;
  }

  .nav-links button {
    border: 1px solid white;
    color: white;
    background: transparent;
  }
  
  .nav-links button:hover,
  .nav-links button:active {
    background: #366680;
    color: #ffffff;
  }
}


.side-drawer {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  height: 100vh;
  width: 70%;
  background: #69d9b0 ;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  z-index: 10;
}

.main-navigation__menu-btn {
  width: 3rem;
  height: 3rem;
  background: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-right: 3rem;
  cursor: pointer;
  margin-left: auto;
}

.main-navigation__menu-btn span {
  display: block;
  width: 3rem;
  height: 2.5px;
  background: white;
}

.main-navigation__title {
  color: white;
}

.main-navigation__title a {
  text-decoration: none;
  color: white;
}

.main-navigation__header-nav {
  display: none;
}

.main-navigation__drawer-nav {
  height: 100%;
}

@media (min-width: 768px) {
  .main-navigation__menu-btn {
    display: none;
  }

  .main-navigation__header-nav {
    display: block;
  }
}
*{
    margin:0;
    padding:0;
    font-family: "CodeBold";
}
h1,h2{
  color:#69d9b0;
}
h5{
  color:#69d9b0;
  font-size: 1.5em;
}
h5.reverse{
  color:#fff;
}
h1.reverse{
  color:#366680;
}
p{
  font-family: "Roboto";
  color:#fff;
}
p.reverse{
  color:#366680;
}
@font-face {
    font-family: "CodeLight";
    src: url(/static/media/CodeLight.4a8b44ce.eot); /* IE9 Compat Modes */
    src: url(/static/media/CodeLight.4a8b44ce.eot?#iefix) format("embedded-opentype"), 
      url(/static/media/CodeLight.352fb8c8.otf) format("opentype"), 
      url(/static/media/CodeLight.e80dc3b0.svg) format("svg"), 
      url(/static/media/CodeLight.6267bed1.ttf) format("truetype"), 
      url(/static/media/CodeLight.f28fd9eb.woff) format("woff"), 
      url(/static/media/CodeLight.ce2a0767.woff2) format("woff2"); /* Modern Browsers */
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "CodeBold";
    src: url(/static/media/CodeBold.5aa7c2f6.eot); /* IE9 Compat Modes */
    src: url(/static/media/CodeBold.5aa7c2f6.eot?#iefix) format("embedded-opentype"), 
      url(/static/media/CodeBold.33485b5f.otf) format("opentype"), 
      url(/static/media/CodeBold.465ca9e0.svg) format("svg"), 
      url(/static/media/CodeBold.2452790f.ttf) format("truetype"), 
      url(/static/media/CodeBold.c72af5a9.woff) format("woff"), 
      url(/static/media/CodeBold.8a4de1a5.woff2) format("woff2"); /* Modern Browsers */
    font-weight: normal;
    font-style: normal;
  }

#Home{
    min-height: 100vh;

}
.home-text{
    position:relative;
    padding:1.5rem;
    margin:auto;
    top:5rem;
    border-radius: 10px;
    width:90%;
    max-width: 420px;
}
.home-text > h1 {
    color:#69d9b0;
    line-height: 1.25em;
    letter-spacing: 0.1em;
}
.home-text p {
    margin:1rem 0 0;
    line-height: 1.5em;
    font-family: "Roboto" !important;
    font-weight: 400;
    color: #fff;
}

@media (min-width: 1000px) {
    .home-text{
        margin-left: 60%; 
        top:10rem;
    }

    

}

  
#Portfolio{
    height:100vh;
    width:100%;
    background:#69d9b0;
    color:#fff;
    position: relative;
    align-items: center;
    display: flex;

}
#Portfolio > div {
    position: relative;
    margin:auto;
    width:90%;
    max-width:800px;
   height:50vh;
   color:#366680;
 
}


.work-grid{
    display: grid; 
    grid-template-columns: 1fr 1fr; 
    grid-template-rows: 1fr 1fr; 
    grid-gap: 1em 1em; 
    gap: 1em 1em; 
    grid-template-areas: 
      ". ."
      ". ."; 
    justify-items: center;
    margin: 1rem 0;
}

.work-grid > div {
    text-align: center;
    background-color:#366680;
    width:100%;
    padding:4rem 0;
}

.work-grid > div > h5{
    color:#fff;
    font-size: 1em;
}
#About{
    height:100vh;
    display: flex;
    width:100%;
    color: #366680;
}

#About > div {
    margin: auto;
}
#About > div > div {
    position: relative;
    display: flex;
    
}

.partner-imgs{
    width: 90%;
    margin:0.5rem auto;
}
.partner-imgs > div{
    flex:1 1;
    margin:auto 0;
    padding:0 0.25rem;
 
}
.partner-img{
   
}

#About > div > div:first-child{
    width: 95%;
    max-width: 1000px;
    margin: auto;
    display: flex;
    flex-direction: column;
}

#About > div > div:first-child > div:first-child{
    flex:3 1;
    margin: auto;
    padding:0.5rem;
}
#About > div > div:first-child > div:last-child{
    flex:2 1;
    margin: auto;
    padding:0.5rem;


}

#About > div > div > div > h2{
    color: #69d9b0;
    font-size: 2em;
    margin:0.5em 0;
}
#About > div > div > div > div > h5{
    color: #69d9b0;
    font-size: 1.5em;
}
#About > div > div > div > div > p{

    font-size: 0.75em;
}
#About > div > div > div > p {
    font-family: "Roboto";
    font-size: 1em;
  
}
.about-grid{
    display: grid; 
    grid-template-columns: 1fr 1fr; 
    grid-template-rows: 1fr 1fr; 
    grid-gap: 0.25em 0.25em; 
    gap: 0.25em 0.25em; 
    grid-template-areas: 
      ". ."
      ". ."; 
    justify-items: center;
    text-align: center;
}
.about-grid > div{
    padding: 1rem 0;
    font-size: 1.5em;
}
.about-grid > div > h5{
    margin:0.25em 0;
}
@media (min-width: 1000px) {
    #About > div > div:first-child{
        flex-direction: row;
    }
    .partner-imgs > div{
        padding: 0 0.85rem;
    }
    #About > div > div {
       
    }
}
#Vehicles{
    height:100vh;
    background-color: #366680;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    padding: 4rem 0;
}
#Vehicles > div {
    width:90%;
    max-width: 500px;
    margin: auto;
    line-height: 1.25;
    color: #fff;
    
}
#Vehicles > div:first-child{
}

#vehicles-box{
}
#vehicles-box img{
    display: block;
    height:12%;
    max-height: 75px;
    padding: 0.5rem 0;
    margin-left: auto;
}



@media (min-width: 1000px) {

    #Vehicles{
        flex-direction: row;

    }
    #Vehicles > div:first-child{
        margin:auto;
    
    }
    #vehicles-box{
        position: absolute;
        bottom:25%;
        right:-2%;
    }
    #vehicles-box > img{
    
        display: block;
        padding:1rem;
        margin-left: auto;
    }
}
#contact{
    display: flex;
    min-height: 100vh;
    margin-top:3rem;
    flex-direction:column;

}
#contact > div{
    background-color: #fff;
    padding:1rem;
    color:#366680;
    width:90%;
    max-width: 500px;
    margin:auto;
 

}
#contact img{
    padding:1rem 0;
}
#contact > div > p {
    font-family: 'roboto';
}
#contact p>a {
    color:#36668081;
    text-decoration: none;
    font-family: 'roboto';
}
#contact p>a:hover{
    color:#366680;
    text-decoration:underline;
    transition:0.8s;
}
.contact-right{
    display:flex;
    ;
} 
.contact-right>div{
    flex:4 1;
    margin:auto 0;
   
}

.contact-right > div:first-child{
    flex:0.5 1;
}


@media (min-width: 1000px) {
    #contact{
        margin-top:0;
        flex-direction:row;
    
    }

}
#impressum{
    width:90%;
    max-width: 800px;
    margin: 10rem auto;
    padding:0 0 10rem;
}
#impressum > * {
    margin: 1rem 0;
}
#impressum > p {
    font-family: 'Roboto';
    color:#366680;
}
#impressum > h3,h4, li{
    color:#366680;
   
}

#impressum li{
    margin-left:1em;
    font-family: 'Roboto';

}
