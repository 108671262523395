#impressum{
    width:90%;
    max-width: 800px;
    margin: 10rem auto;
    padding:0 0 10rem;
}
#impressum > * {
    margin: 1rem 0;
}
#impressum > p {
    font-family: 'Roboto';
    color:#366680;
}
#impressum > h3,h4, li{
    color:#366680;
   
}

#impressum li{
    margin-left:1em;
    font-family: 'Roboto';

}